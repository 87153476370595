<template>
    <v-container class="d-flex justify-center align-center fill-height" v-if="loading">
        <v-progress-circular indeterminate color="primary" size="50" width="8" />
    </v-container>

    <div v-else>

      <div class="d-flex justify-end" v-if="isManager">
        <v-btn color="primary" @click="dialogRankingSettings = true">
          Nastavení žebříčku
        </v-btn>
        
      </div>

      <!-- Dialog pro natsavení Rankingu -->
        <v-dialog v-model="dialogRankingSettings" max-width="800px">
          <v-card>
            <v-card-title class="text-h5">Nastavení žebříčku</v-card-title>
            <v-card-text>
              <v-row class="d-flex justify-end">

                <v-col cols="12" md="6">
                  <v-btn color="green" @click="dialogRankingCreate = true" class="w-100">
                    Přidat závod
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn color="red" @click="dialogRankingDelete = true" class="w-100 mb-3">
                    Odebrat závod
                  </v-btn>
                </v-col>
              </v-row>
              <v-text-field
                v-model="newRanking.drop_count"
                label="Počet škrtaných závodů"
                type="number"
                :rules="[v => (v !== undefined && v !== null && v !== '' && Number(v) >= 0) || 'Hodnota musí být kladné číslo nebo 0']"
              ></v-text-field>

              <!-- Text field pro race_ranking_categories -->

              <div v-for="(race, index) in newRanking.races" :key="race.id || index" :style="{ background: index % 2 === 1 ? '#EDF5FF' : '' }">
                <v-select
                  v-model="newRanking.races[index].race_ranking_categories"
                  :items="rankingCategories"
                  :label="`Kategorie pro ranking ze závodu: ${formatShortDate(race.race_date)} ${race.race_name}`"
                  :chips="true"
                  :multiple="true"
                ></v-select>
                <v-autocomplete
                  v-model="newRanking.races[index].competitors_avg"
                  :items="competitors_avg_accounts"
                  item-value="id"
                  item-title="fullName"
                  :label="`Průměry pro závodníky ze závodu: ${formatShortDate(race.race_date)} ${race.race_name}`"
                  multiple
                  density="comfortable"
                  hint="Průměry v případě, že je závod zrušen"
                  no-data-text="Nikdo s tímto jménem nenalezen"
                ></v-autocomplete>
              </div>

            </v-card-text>
            <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
            <v-card-actions>
              <v-btn color="grey" text @click="dialogRankingSettings = false">Zrušit</v-btn>
              <v-spacer />
              <v-btn color="green" text @click="saveRanking">Uložit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Dialog pro přidání Race do Rankingu -->
        <v-dialog v-model="dialogRankingCreate" max-width="800px">
          <v-card>
            <v-card-title class="text-h5" color="success">Přidat závod do žebříčku</v-card-title>
            <v-card-text>
              <v-select
                v-model="selectedRaceId"
                :items="allRaces"
                item-title="label"
                item-value="race_id"
                label="Vyber závod ze seznamu"
                clearable
                hide-details="true"
                no-data-text="Žádné závody nejsou k dispozici"
              />
            </v-card-text>
            <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
            <v-alert
              text="Pokud správci soutěže změní úroveň závodu, tvoje volba zařazení se zruší."
              type="info"
              variant="tonal"
            />
            <v-card-actions>
              <v-btn color="grey" text @click="dialogRankingCreate = false">Zrušit</v-btn>
              <v-spacer />
              <v-btn color="green" text @click="createRankingRace">Vložit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Dialog pro smazání Race z Raningu -->
        <v-dialog v-model="dialogRankingDelete" max-width="800px">
          <v-card>
            <v-card-title class="text-h5">Smazat závod ze žebříčku</v-card-title>
            <v-card-text>
              
              <v-select
                v-model="selectedDeleteRaceId"
                :items="deleteRaceOptions"
                item-title="label"
                item-value="race_id"
                label="Vyber závod ze seznamu"
                clearable
                hide-details="true"
                no-data-text="Žádné závody nejsou k dispozici"
              />
            </v-card-text>
            <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
            <v-alert
              text="Pokud správci soutěže změní úroveň závodu, tvoje volba vyřazení se zruší."
              type="info"
              variant="tonal"
            />
            <v-card-actions>
              <v-btn color="grey" text @click="dialogRankingDelete = false">Zrušit</v-btn>
              <v-spacer />
              <v-btn color="red" text @click="deleteRankingRace">Vyřadit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <!-- Tabulky kategorií -->
      <div v-for="(table, index) in tableCategories" :key="index" class="mb-8">
        <h2 class="mb-2">{{ table.category_name }}</h2>
        
        <v-data-table 
        :headers="table.headers" 
        :items="table.items" 
        :sort-by="sortRanking"
        class="elevation-2" 
        hide-default-footer
        no-data-text="Nejsou dostupné žádné výsledky"
        density="comfortable"
        >

          <!-- Slot pro vlastní hlavičky -->
          <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
              <tr>
              <th v-for="col in columns" :key="col.value" :class="col.value.startsWith('race_') && !col.event_closed ? 'text-grey' : ''">
                  <div class="header-container">
                  <div v-if="col.value.startsWith('race_')" class="vertical-header" @click="toggleSort(col)">
                      <v-tooltip location="bottom center" origin="auto">
                      <template v-slot:activator="{ props }">
                          <div v-bind="props">{{ col.title }}</div>
                          <div v-bind="props" class="race-date" @click="toggleSort(col)">{{ $formatDate(col.race_date) }}</div>
                          <v-icon
                          v-if="isSorted(col)"
                          :icon="getSortIcon(col)"
                          color="medium-emphasis"
                          ></v-icon>
                          <v-icon
                          v-else
                          :icon="getSortIcon(col)"
                          color="grey-lighten-1"
                          ></v-icon>
                      </template>
                      {{ col.event_name }}<br>
                      {{ col.title }}<br>
                      {{ $formatDate(col.race_date) }}
                      </v-tooltip>
                  </div>
                  <div v-else @click="toggleSort(col)">
                      {{ col.title }}
                      <v-icon
                      v-if="isSorted(col)"
                      :icon="getSortIcon(col)"
                      color="medium-emphasis"
                      ></v-icon>
                      <v-icon
                      v-else
                      :icon="getSortIcon(col)"
                      color="grey-lighten-1"
                      ></v-icon>
                  </div>
                  </div>
              </th>
              </tr>
          </template>

          <!-- Pevné sloupce -->
          <template v-slot:[`item.place`]="{ value }">
              <strong>{{ value }}</strong>
          </template>
          <template v-slot:[`item.full_name`]="{ item }">
              <router-link :to="'/ucet/' + item.account_id" style="white-space: nowrap;">{{ item.full_name }}</router-link>
          </template>
          <template v-slot:[`item.competitor_index`]="{ value }">
              {{ value }}
          </template>
          <template v-slot:[`item.total_points`]="{ value }">
              <strong class="highlighted">{{ value }}</strong>
          </template>

          <!-- Dynamické sloupce pro jednotlivé závody -->
          <template v-for="(race, idx) in globalRaces" :key="idx" v-slot:[`item.race_${idx}`]="{ value }">
              <div :class="{'text-grey': !race.event_closed}">
              <template v-if="!value || value.value === null">
                  <!-- prázdná buňka -->
              </template>
              <template v-else>
                  <v-tooltip v-if="value.is_dropped" location="top center" origin="auto" text="Škrtnutý nejhorší výsledek">
                      <template v-slot:activator="{ props }">
                      <span v-bind="props" class="strikethrough highlighted"> {{ value.value }} </span>
                      </template>
                  </v-tooltip>  
                  <v-tooltip v-else-if="value.is_avg" location="top center" origin="auto" text="Započtený průměr">
                      <template v-slot:activator="{ props }">
                      <span v-bind="props" class="avg-cell highlighted"> {{ value.value }} </span>
                      </template>
                  </v-tooltip>  
                  <span v-else class="highlighted">
                  {{ value.value }}
                  </span>
              </template>
              </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </template>
  
  
<script setup>
import { computed, ref, watch } from 'vue';
import {useAuthStore} from '/auth.js';
import apiClient from '/api.js';
import { useRoute } from 'vue-router';
import {useSnackbarStore} from '@/stores/snackbarStore'
import { useDateTimeFormat } from '@/composables/useDateTimeFormat';




const props = defineProps({
loading: Boolean,
ranking: Object
});

const emit = defineEmits(["refreshRanking"]);



const authStore = useAuthStore();
const snackbar = useSnackbarStore()
const { formatShortDate } = useDateTimeFormat();



const isManager = authStore.roles.includes('manager');  // Načtení práv

const sortRanking = [{ key: 'total_points', order: 'desc' }]; // Klíč pro řazení


// Globální závody z API
const globalRaces = computed(() => {
    return props.ranking ? props.ranking.races : [];
});

// Transformace kategorií do tabulek
const tableCategories = computed(() => {
  if (!props.ranking) return [];

  // Fixní hlavičky
  const fixedHeaders = [
    { title: 'Poř.', value: 'place', align: 'center' },
    { title: 'Příjmení a jméno', value: 'full_name', sortable: true },
    { title: 'Index', value: 'competitor_index', sortable: true },
    { title: 'Body', value: 'total_points', sortable: true, align: 'center' }
  ];
  // Dynamické hlavičky pro závody
  const raceHeaders = globalRaces.value.map((race, index) => ({
    title: race.race_name,
    value: `race_${index}`,
    event_closed: race.event_closed,
    sortable: true,
    align: 'center',
    race_date: race.race_date,
    event_name: race.event_name,
    // Přidáme vlastní funkci řazení, která porovná pouze hodnotu "value"
    sort: (a, b) => {
      const aVal = (a && typeof a.value === 'number') ? a.value : 0;
      const bVal = (b && typeof b.value === 'number') ? b.value : 0;
      return aVal - bVal;
    }
  }));
  
  
  const headers = [...fixedHeaders, ...raceHeaders];

  // Transformace dat kategorií
  const itemsByCategory = props.ranking.categories.map(category => {
    const items = category.entries.map(entry => {
      const row = {
        place: entry.place,
        full_name: `${entry.last_name} ${entry.first_name}`,
        account_id: entry.account_id,
        competitor_index: entry.competitor_index,
        total_points: entry.total_points
      };
      entry.points.forEach((point, idx) => {
        row[`race_${idx}`] = point;
      });
      return row;
    });
    return {
      category_name: category.category_name.startsWith("W") 
        ? "D" + category.category_name.slice(1) 
        : category.category_name,
      headers,
      items
    };
  });
  return itemsByCategory;
});


// Editace dat manažerem //

const dialogRankingSettings = ref(false)

//Výběr uživatelů pro zápočet průměru
const competitors_avg_accounts = ref([]);

// Načítání účtů z API
const fetchAccounts = async () => {
  try {
    const response = await apiClient.get('account/?list=true'); // Volání API s parametrem simple=true
    competitors_avg_accounts.value = response.data;


  } catch (error) {
    console.error('Chyba při načítání účtů:', error);
  }
};

// Načtení accounts až po props (Ranking)
watch(
  () => props.ranking,
  (newVal) => {
    if (
      isManager &&
      newVal &&
      Array.isArray(newVal.races) &&
      newVal.races.length > 0
    ) {
      fetchAccounts();
    }
  },
  { immediate: false }
);





const rankingCategories = [
  "W12", "W14", "W16", "W19", "W21", "W35", "W45", "W55", "W65",
  "M12", "M14", "M16", "M19", "M21", "M40", "M50", "M60", "M70"
];


// Inicializace objektu pro nová data – naplníme jej výchozí hodnotou z props.ranking
// Inicializace newRanking – převedeme string na pole a vyfiltrujeme jen platné hodnoty
const newRanking = ref({
  drop_count: props.ranking ? props.ranking.drop_count : '',
  races: props.ranking && props.ranking.races
    ? props.ranking.races.map(race => ({
         race_name: race.race_name,
         competitors_avg: race.competitors_avg ? race.competitors_avg.slice() : [],
         // Předpokládáme, že API vrací hodnotu jako string, oddělenou čárkami
         race_ranking_categories: typeof race.race_ranking_categories === 'string'
           ? race.race_ranking_categories.split(',').filter(cat => rankingCategories.includes(cat))
           : race.race_ranking_categories
      }))
    : []
});


// Watch, který zajistí aktualizaci newRanking při změně props.ranking
watch(
  () => props.ranking,
  (newVal) => {
    if (newVal) {
      newRanking.value = {
        drop_count: newVal.drop_count,
        races: newVal.races.map(race => ({
          race_name: race.race_name,
          race_date: race.race_date,
          competitors_avg: race.competitors_avg ? race.competitors_avg.slice() : [],
          race_ranking_categories: typeof race.race_ranking_categories === 'string'
            ? race.race_ranking_categories.split(',').filter(cat => rankingCategories.includes(cat))
            : race.race_ranking_categories
        }))
      };
    }
  },
  { immediate: true }
);

const errorMessage = ref("")
const currentYear = new Date().getFullYear();
const route = useRoute();

const rankingUrl = computed(() => {
  const region = route.params.region;
  return region ? `/ranking/${region}/${currentYear}/` : `/ranking/national/${currentYear}/`;
});


const saveRanking = async () => {
  try {
    // Sestavte payload – můžete jej přizpůsobit dle API
    const payload = {
      drop_count: newRanking.value.drop_count,
      races: newRanking.value.races.map(race => ({
        race_ranking_categories: race.race_ranking_categories,
        competitors_avg: race.competitors_avg,
      }))
    };
    // Odeslání dat metodou PUT (URL upravte dle svého API)
    await apiClient.put(rankingUrl.value, payload)

    dialogRankingSettings.value = false;
    snackbar.showSnackbar("Žebříček byl úspěšně uložen.");
    emit("refreshRanking");
    
  } catch (error) {
    const detail = error.response.data.detail || 'Neznámá chyba.';
    console.error('Chyba při mazání třídy:', error.message);
    errorMessage.value = `Nepodařilo se smazat třídu. ${detail}`;
  }
}


const dialogRankingCreate = ref(false)
// Sledování otevření dialogu pro přidání Race a další jeho logika

watch(dialogRankingCreate, (isOpen) => {
  if (isOpen) {
    fetchAllRaces();
  }
});

const allRaces = ref([]);
const fetchAllRaces = async () => {

  try {
    const response = await apiClient.get(`${rankingUrl.value}?all_races=true`);
    allRaces.value = [
      { race_id: null, label: '--- Vyber závod ---' },
      ...response.data.races.map(race => ({
        race_id: race.race_id,
        label: `${formatShortDate(race.race_date)} - ${race.race_name} (${race.event_name})`,
      }))
    ];

  } catch (error) {
    errorMessage.value = "Závody pro výběr se nepodařilo načíst";
  // } finally {
  }
};


const selectedRaceId = ref(null);

const createRankingRace = async () => {

  if (!selectedRaceId.value) {
    errorMessage.value = "Vyberte závod, který chcete přidat.";
    return;
  }

  try {

    // Odeslání dat metodou PUT (URL upravte dle svého API)
    await apiClient.post(`${rankingUrl.value}?race_id=${selectedRaceId.value}`)

    dialogRankingCreate.value = false;
    dialogRankingSettings.value = false;
    selectedRaceId.value = null;
    snackbar.showSnackbar("Závod byl do žebříčku úspěšně přiřazen.");
    emit("refreshRanking");
    
  } catch (error) {
    const detail = error.response.data.detail || 'Neznámá chyba.';
    errorMessage.value = `Nepodařilo se smazat třídu. ${detail}`;
  }
}


const dialogRankingDelete = ref(false)
const selectedDeleteRaceId = ref(null);

const deleteRaceOptions = computed(() => {
  return props.ranking?.races.map(race => ({
    race_id: race.race_id,
    label: `${formatShortDate(race.race_date)} - ${race.event_name} (${race.race_name})`,
  })) || [];
});

const deleteRankingRace = async () => {

  if (!selectedDeleteRaceId.value) {
    errorMessage.value = "Vyberte závod, který chcete vyřadit.";
    return;
  }

  try {
    await apiClient.delete(`${rankingUrl.value}?race_id=${selectedDeleteRaceId.value}`)

    dialogRankingDelete.value = false;
    dialogRankingSettings.value = false;
    selectedDeleteRaceId.value = null;
    snackbar.showSnackbar("Závod byl ze žebříčku úspěšně vyřazen.");
    emit("refreshRanking");
    
  } catch (error) {
    errorMessage.value = "Nepodařilo se vyřadit závod z žebříčku.";
  }
}


</script>
  
  <style scoped>
  .strikethrough {
    text-decoration: line-through;
  }
  .avg-cell {
    background-color: orange;
    color: white;
    padding: 4px;
    border-radius: 4px;
  }
  .text-grey {
    color: grey !important;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  
  .highlighted {
    font-size: 15px;
  }
  
  .vertical-header {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    padding: 5px;
    font-size: 13px;
    margin-left: auto;
    margin-right: auto;
    min-height: 90px;
    vertical-align: bottom;
  }
  
  .race-date {
  
    color: gray;
  }
  
  .header-container {

    white-space: nowrap;
  }
  
  
  
  :deep() .v-table .v-table__wrapper > table > thead > tr > th {
    border-bottom: none!important;
    padding: 0px 10px;
  }
  
  :deep() .v-table .v-table__wrapper > table > thead > tr > th:nth-child(4) {
    background-color: rgb(253, 234, 200) !important;
  }
  
  /* Zachování borderu jen v těle tabulky */
  :deep() .v-table .v-table__wrapper > table > tbody > tr > td:not(:last-child) {
    border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
  }
  
  :deep() .v-table .v-table__wrapper > table > thead > tr > th {
    vertical-align: bottom;
  }
  
  
  /* styl pro sloupce pořadí a body na zarovnání na střed */
  :deep() .v-table .v-table__wrapper > table > thead > tr > th:nth-child(1),
  :deep() .v-table .v-table__wrapper > table > thead > tr > th:nth-child(4) {
    text-align: center!important;
  
  
  }
  
  /* Speciální styl pro sloupce "Příjmení a jméno" a "Index" (zarovnání vlevo) */
  :deep() .v-table .v-table__wrapper > table > thead > tr > th:nth-child(2),
  :deep() .v-table .v-table__wrapper > table > thead > tr > th:nth-child(3) {
    text-align: left !important;
  
  }

  :deep() .v-table > .v-table__wrapper > table > tbody > tr > td {
    padding: 0px 10px;
  }

  /* Oranžové pozadí ve sloupci body */
  :deep(.v-data-table__td:nth-child(4)) { 
    background-color: rgb(253, 234, 200) !important;
  }
  
  </style>
  