<template>
  <v-container>
    <!-- Výběr oblasti -->
     <v-row class="mb-4">
      <v-col cols="12" md="3">
        <v-select
          v-model="selectedRegion"
          :items="regions"
          label="Vyber oblast"
          item-title="title"
          item-value="value"
          density="comfortable"
          hide-details="true"
        />
      </v-col>
      <v-spacer />
      <v-col cols="12" md="2" class="d-flex align-center justify-end">
          <h4>Počet škrtaných závodů: {{ ranking.drop_count}}</h4>
      </v-col>
    </v-row>
    <RankingTable 
    :loading="loading" 
    :ranking="ranking" 
    @refreshRanking="fetchRanking" 
    />

    <!-- Zobrazení zprávy, pokud nejsou závody -->
    <v-alert v-if="!ranking.races.length && !ranking.categories.length && !loading" type="info" class="mt-4">
      Nejsou vypsané žádné závody do tohoto žebříčku.
    </v-alert>

  </v-container>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import apiClient from '/api.js';
import { useAuthStore } from '/auth.js';
import RankingTable from '@/components/shared/RankingTable.vue';

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const loading = ref(true);
const ranking = ref({ races: [], categories: [] }); // **Zajištění, že nebude undefined**
const currentYear = new Date().getFullYear();


// **Seznam regionů pro výběr**
const regions = [
  { value: 'A', title: 'Praha' },
  { value: 'B', title: 'Střední Čechy' },
  { value: 'C', title: 'Jižní Čechy' },
  { value: 'D', title: 'Západní Čechy' },
  { value: 'E', title: 'Severní Čechy' },
  { value: 'F', title: 'Východní Čechy' },
  { value: 'G', title: 'Jižní Morava' },
  { value: 'H', title: 'Severní Morava a Slezsko' }
];

// **Výchozí region z URL nebo localStorage**
const selectedRegion = ref(getDefaultRegion());

function getDefaultRegion() {
  const routeRegion = route.params.region?.toUpperCase(); // Region z URL
  if (regions.some(region => region.value === routeRegion)) {
    return routeRegion; // Pokud je validní, použijeme ho
  }

  const index = authStore.index || ''; // Načtení indexu z Pinia store
  const firstLetter = index.charAt(0).toUpperCase(); // První písmeno (velké)
  return regions.some(region => region.value === firstLetter) ? firstLetter : 'G'; // Ověření
}

onMounted(() => {
  fetchRanking();
});

// **Sledování změny regionu a aktualizace dat + URL**
watch(selectedRegion, (newRegion) => {
  router.push({ path: `/ranking/${newRegion}/${currentYear}/` }); // Aktualizace URL
  fetchRanking(); // Načtení nových dat
});


// **Načítání dat z API**
async function fetchRanking() {
  loading.value = true;
  ranking.value = { races: [], categories: [] }; // **Reset před načtením nových dat**
  
  try {
    const response = await apiClient.get(`/ranking/${selectedRegion.value}/${currentYear}/`);
    ranking.value = response.data || { races: [], categories: [] }; // **Zajistí, že nevrátíme undefined**
  } catch (error) {
    console.error('Chyba při načítání dat:', error);
  } finally {
    loading.value = false;
  }
}
</script>