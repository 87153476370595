import axios from 'axios';
import router from '/src/router/index.js';

// Získání časové zóny uživatele
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-User-Timezone': userTimeZone
  }
});


// Přidání response interceptoru k ošetření expirovaných tokenů a autorizačních chyb. Expirované toky se smažou včetně uživ. dat
apiClient.interceptors.response.use(response => response, async error => {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    // Zkontrolujte, jestli není aktuální cesta stránka pro reset hesla nebo login
    const path = router.currentRoute.value.path;
    const basePath = path.split('/')[1]; // Rozdělí cestu a bere první segment, protože jinak to bralo i token a uid
    if (basePath !== 'login' && basePath !== 'reset_hesla') {
      // Smazání cookie
      try {
        // požadavek na backend, který smaže HttpOnly cookie
        await apiClient.post('/logout/');
      } catch (logoutErr) {
        console.warn('Chyba při mazání cookie:', logoutErr);
      }

      // Smazání uživatelských dat z localStorage

      localStorage.removeItem('authToken'); // ToDo odebrat v květnu 2025
      localStorage.removeItem('userClub');
      localStorage.removeItem('userID');
      localStorage.removeItem('index');
      localStorage.removeItem('firstName');
      localStorage.removeItem('last_name');
      localStorage.removeItem('roles');
      
      // Zobrazit upozornění uživateli
      alert("Tvoje přihlášení vypršelo. Přihlaš se prosím znovu.");
      
      // Přesměrování na přihlašovací stránku nebo zobrazení chyby
      // Zvažte použití Vue Router pro přesměrování, pokud je to možné
      router.go('/login');
      //router.push('/login');
    }
  }
  return Promise.reject(error);
});

export default apiClient;


// místo axiosu se importuje:    import apiClient from '/api.js';
