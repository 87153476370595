import {defineStore} from 'pinia'
import {ref} from 'vue'

export const useSnackbarStore = defineStore('SnackbarStore', () => {
    const isOpen = ref(false)
    const message = ref('')

    const showSnackbar = (msg, timeout = 5000) => {
        message.value = msg
        isOpen.value = true

        setTimeout(() => {
            closeSnackbar()
        }, timeout)

    }

    function closeSnackbar() {
        isOpen.value = false
    }

    return {isOpen, message, showSnackbar}
})