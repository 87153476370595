import apiClient from '/api.js';
import router from '/src/router'
import { defineStore } from 'pinia';


export const useAuthStore = defineStore('auth', {
  state: () => ({
    roles: JSON.parse(localStorage.getItem('roles') || '[]'),
    userID: localStorage.getItem('userID') || '',
    userClub: localStorage.getItem('userClub') || '',
    firstName: localStorage.getItem('firstName') || '',
    last_name: localStorage.getItem('last_name') || '',
    index: localStorage.getItem('index') || '',

  }),
  getters: {
    isLoggedIn: (state) => !!state.userID,
  },
  actions: {

    // aktualizace rolí uživatelů
    updateRoles(newRoles) {
      this.roles = newRoles;
      localStorage.setItem('roles', JSON.stringify(newRoles));
    },


    async login(email, password) {
      try {
        const response = await apiClient.post('/login/', {
          email,
          password,
        }, { withCredentials: true });
        this.$patch({
          roles: response.data.roles,
          userID: response.data.userID,
          userClub: response.data.userClub,
          firstName: response.data.first_name,
          last_name: response.data.last_name,
          index: response.data.index,

        });
        localStorage.setItem('userID', this.userID);
        localStorage.setItem('roles', JSON.stringify(response.data.roles));
        localStorage.setItem('userClub', this.userClub);
        localStorage.setItem('firstName', this.firstName);
        localStorage.setItem('last_name', this.last_name);
        localStorage.setItem('index', this.index);

      } catch (error) {
        console.error('Chyba při přihlášení', error);
        
        // Zde extrahujeme chybovou zprávu
        let errorMessage = "Došlo k neznámé chybě při přihlašování.";
        if (error.response) {
          // Server vrátil chybovou odpověď
          if (error.response.data && error.response.data.detail) {
            errorMessage = error.response.data.detail;
          } else if (error.response.status === 401) {
            errorMessage = "Neplatné přihlašovací údaje.";
          }
        } else if (error.request) {
          // Požadavek byl odeslán, ale neobdrželi jsme žádnou odpověď
          errorMessage = "Server neodpovídá. Zkuste to prosím později.";
        }
        throw new Error(errorMessage);
      }
    },

    
    async logout() {

      try {
        await apiClient.post('/logout/'); // response smaže cookie
      } catch (e) {
        console.warn('Logout failed (možná už je smazaná cookie)');
      }

      this.userID = '';
      this.roles = '';
      this.userClub = '';
      this.firstName = '';
      this.last_name = '';
      this.index = '';
      localStorage.removeItem('authToken');
      localStorage.removeItem('userID');
      localStorage.removeItem('roles');
      localStorage.removeItem('userClub');
      localStorage.removeItem('firstName');
      localStorage.removeItem('last_name');
      localStorage.removeItem('index');

      router.push('/');
      },
  },
});
