<template>
  <v-dialog max-width="1500px">
      <v-card>
        <v-card-title>Přehled tříd</v-card-title>
        <RoleTable
            :account-id="props.accountId"
        />
        <v-card-actions>
          <v-btn text color="grey" @click="$emit('close-dialog')">Zavřít</v-btn>

        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script setup>
import RoleTable from "@/components/shared/RoleTable.vue";
import {defineEmits} from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  accountId: Number
})
defineEmits(['close-dialog']);

</script>

<style scoped>

</style>