<template>
  <v-container>
    <div v-for="(club, idx) in tableClubs" :key="idx" class="mb-8">
      <v-card-title class="mb-2">{{ club.club_name }}</v-card-title>
      
      <v-data-table 
        :headers="club.headers" 
        :items="club.items" 
        :sort-by="['full_name']"
        class="elevation-2" 
        hide-default-footer
        @touchstart.stop
        @touchmove.stop
        no-data-text="Nejsou dostupné žádné výsledky"
        density="compact"
        :items-per-page="-1"
      >
        <!-- Vlastní hlavičky -->
        <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
          <tr>
            <th v-for="col in columns" :key="col.value" @click="toggleSort(col)">
              <template v-if="col.date">
                <v-tooltip location="bottom center" origin="auto">
                  <template v-slot:activator="{ props }">
                    <div v-bind="props" class="header-container">
                      {{ col.title }}
                      <v-icon v-if="isSorted(col)" :icon="getSortIcon(col)" color="medium-emphasis" />
                      <v-icon v-else :icon="getSortIcon(col)" color="grey-lighten-1" />
                    </div>
                  </template>
                  {{ $formatDate(col.date) }}
                </v-tooltip>
              </template>
              <template v-else>
                <div class="header-container">
                  {{ col.title }}
                  <v-icon v-if="isSorted(col)" :icon="getSortIcon(col)" color="medium-emphasis" />
                  <v-icon v-else :icon="getSortIcon(col)" color="grey-lighten-1" />
                </div>
              </template>
            </th>
          </tr>
        </template>
        
         <!-- Slot pro fixní sloupec si_rent (s fixním klíčem "si_rent") -->
         <template v-slot:[`item.si_rent`]="{ item }">
          <div class="text-center">
            <v-icon v-if="item.si_rent === '1'" color="green">mdi-check-circle</v-icon>
          </div>
        </template>

        <!-- Extra řádek (footer) -->
        <template v-slot:[`body.append`]>
          <tr>
            <td :colspan="club.headers.length - 1" class="text-right"><strong>Celkem:</strong></td>
            <td class="text-center"><strong>{{ club.club_payment }} Kč</strong></td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script setup>
import { computed } from 'vue';
// import { useDateTimeFormat } from '@/composables/useDateTimeFormat';

const props = defineProps({
  payment: Object
});

// const { formatShortDate } = useDateTimeFormat();

// Definice pořadí pro food typy
const foodOrder = { sn: 0, ob: 1, ve: 2 };

// Najdeme fixní službu si_rent (pokud existuje)
const siRentService = computed(() => {
  if (!props.payment?.services) return null;
  return props.payment.services.find(service => service.type === 'si_rent') || null;
});

// Dynamické služby: všechny kromě si_rent
const dynamicServices = computed(() => {
  if (!props.payment?.services) return [];
  return props.payment.services.filter(service => service.type !== 'si_rent');
});

// Seřadíme dynamické služby podle pravidel:
// - Nejprve accommodation, potom food (podle data vzestupně, a u food podle foodOrder),
// - ostatní podle id.
const sortedDynamicServices = computed(() => {
  return dynamicServices.value.slice().sort((a, b) => {
    const orderPriority = { accommodation: 0, food: 1 };
    const prioA = orderPriority[a.type] ?? 2;
    const prioB = orderPriority[b.type] ?? 2;
    if (prioA !== prioB) return prioA - prioB;
    if ((a.type === 'accommodation' || a.type === 'food') && a.date && b.date) {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      if (a.type === 'food') {
        const orderA = foodOrder[a.food_type] ?? 99;
        const orderB = foodOrder[b.food_type] ?? 99;
        return orderA - orderB;
      }
    }
    return String(a.id).localeCompare(String(b.id));
  });
});

// Vytvoříme hlavičky pro dynamické služby (bez si_rent)
const dynamicHeaders = computed(() => {
  return sortedDynamicServices.value.map(service => ({
  //  title: formatShortDate(service.date) + " " + service.name,
    title: service.name,
    value: service.id,
    sortable: false,
    align: 'center',
    date: service.date
  }));
});

// Vytvoříme fixní hlavičku pro si_rent s pevně daným klíčem "si_rent"
const siRentHeader = computed(() => {
  if (!siRentService.value) return null;
  return {
    title: siRentService.value.name,
    value: 'si_rent',
    sortable: false,
    align: 'center',
    type: 'si_rent'
  };
});

// Sestavíme celkovou hlavičku:
// - Fixní hlavičky, potom dynamické hlavičky, pak fixní sloupec si_rent (pokud existuje) a nakonec sloupec Vklad.
const headers = computed(() => {
  return [
    { title: 'Příjmení a jméno', value: 'full_name', sortable: true },
    { title: 'Index', value: 'competitor_index', sortable: true },
    ...dynamicHeaders.value,
    ...(siRentHeader.value ? [siRentHeader.value] : []),
    { title: 'Vklad', value: 'vklad', align: 'center' }
  ];
});

// Mapování klubů
const tableClubs = computed(() => {
  if (!props.payment?.club_payments) return [];
  
  const clubs = props.payment.club_payments.map(club => {
    // Pokud je club_name "NR", zobrazíme "neregistrovaní"
    const displayName = club.club_name === "NR" ? "neregistrovaní" : club.club_name;
    // Přidáme počet záznamů
    const clubNameWithCount = `${displayName} (${club.entries.length})`;

    let items = club.entries.map(entry => {
      const row = {
        full_name: entry.name,
        competitor_index: entry.competitor_index,
        vklad: entry.competitor_payment
      };
      // Nastavíme hodnotu fixního sloupce si_rent, pokud existuje
      if (siRentHeader.value) {
        // Použijeme fixní klíč "si_rent" a hodnotu z entry.services podle původního id
        row['si_rent'] = entry.services[siRentService.value.id] || '';
      }
      // Dynamické sloupce
      dynamicHeaders.value.forEach(header => {
        row[header.value] = entry.services[header.value] || '';
      });
      return row;
    });
    // Seřadíme řádky podle jména
    items.sort((a, b) => a.full_name.localeCompare(b.full_name));
    return { club_name: clubNameWithCount, club_payment: club.club_payment, headers: headers.value, items };
  });
  
  // Seřadíme kluby – "neregistrovaní" budou vždy na konci
  clubs.sort((a, b) => {
    const aIsNR = a.club_name.startsWith("neregistrovaní");
    const bIsNR = b.club_name.startsWith("neregistrovaní");
    if (aIsNR && !bIsNR) return 1;
    if (!aIsNR && bIsNR) return -1;
    return a.club_name.localeCompare(b.club_name);
  });
  
  return clubs;
});
</script>

<style scoped>

:deep() .v-data-table__td {
  white-space: nowrap!important;
}

.header-container {
  white-space: nowrap;
}
.mb-8 {
  margin-bottom: 2rem;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
</style>
