// composables/useDateTimeFormat.js
// funkce, která umožní můj plugin datetimeformat používat ve scriptu s composition api vue 3

import { getCurrentInstance } from 'vue';

export function useDateTimeFormat() {
  const instance = getCurrentInstance();
  const formatTime = (dateStr) => instance.appContext.config.globalProperties.$formatTime(dateStr);
  const formatDate = (dateStr) => instance.appContext.config.globalProperties.$formatDate(dateStr);
  const formatShortDate = (dateStr) => instance.appContext.config.globalProperties.$formatShortDate(dateStr);
  const formatDateTime = (dateTimeStr) => instance.appContext.config.globalProperties.$formatDateTime(dateTimeStr);

  return { formatTime, formatDate, formatShortDate, formatDateTime };
}