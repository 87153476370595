<template>
  <v-snackbar
      v-model="snackbarRef"
      color="success"
      :timeout="5000"
  >
    {{ props.message }}
  </v-snackbar>
</template>

<script setup>

import {toRef} from "vue";

const props = defineProps({
  showSnackbar: Boolean,
  message: {
    type: String,
    required: true,
  }
});

const snackbarRef = toRef(props, 'showSnackbar')
</script>

