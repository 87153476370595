// src/version.js
export const versions = [
  { version: "0.4.6", date: "2025-04-25", notes: ["V souteži přibyla záložka Přehled plateb, kde najdete přihlášky závodníků na ubytování, stravu a další služby, včetně peněžních vkladů. Vklady jsou včetně startovného.", "V dalším nastavení souteže byla přidána možnost pro editory povolit přihlášení na ubytování pouze na celou dobu soutežě a znemožnit tak v přihláškách výběr pouze některých nocí."] },

  { version: "0.4.5", date: "2025-03-25", notes: ["Integrace národního žebříčku a oblastních žebříčků. Dostanete se do nich přes menu.", "Do kategorií lze přidat kategorii ORG, která se zobrazuje všem věkovým kategoriím a pohlavím, ale je omezená na pořádající oddíl. Určeno pro přihlašování Organizátorů.", "Byla opravena chyba, kdy se v určitých situacích nespárovaly robis účty s přihláškami na některé etapy a nepřepisovaly se kategorie při nahrávání starovky nebo výsledků.", "Do exportu přibyl seznam jmen s čísly čipů a indexů pro zpracovatele výsledků, aby měli k dispozici celou databázi po stažení i offline.", "Byl opraven problém, kdy se nová verze robisu automaticky nenačítala.", "Dalších asi 30 drobných oprav a vylepšení."] },
  { version: "0.4.4", date: "2025-03-03", notes: ["Na stránce přihlašování na soutěž je nyní k dispozici Hromadné přihlašování. Je dostupné pouze pokud můžete vytvořit přihlášku pro více jak jednoho závodníka.", "Stránka s nápovědou byla přepracována a byly doplněny další témata nápovědy.", "Automodul lze v nastavení soutěže pro jednotlivé soutěže vypnout."] },
  { version: "0.4.3", date: "2025-02-25", notes: ["Integrace registrace pro zahraniční kluby a jejich závodníky. Závodníci těchto klubů dostanou robisem přidělený index začínající na X-- kde místo -- je dvoupísmenná zkratka státu. Např. XSK9101.", "Integrace finanční bilance uživatele, která je dostupná v menu přihlášeného uživatele.", "Třídy trenérů a rozhodčích může uživatel s oprávněním manažer editovat nejen ve výpisu všech členů, ale i na profilu uživatele. Byl upraven vzhled těchto tabulek a přidán výpis s kompletní historií tříd daného uživatele. Kontrola vypršení platnosti tříd se provádí každý den automaticky.", "Na stránku soutěže byla přidána mapa, do které mohou uživatelé s oprávněním editovat danou soutěž přidávat mapové body s popisem.", "Po rozkliknutí editace dopravy v automodulu je nyní možné si stáhnout cesťák. Údaje se vyplňují z automodulu a z údajů řidiče, které může uživatel vyplnit v nastavení.", "Api klíč byl znovu přidělen každé etapě. Dále již není nutné do parametrů url posílat race_id. Ten se získá z api klíče.", "Byla opravena chyba, kdy nešly nahrávat výsledky ve formátu XML bez deklarovaného kódování v hlavičce, a nebo pokud se navíc oražené kontroly vypisovaly na konec seznamu kontrol u mezičasů závodníka a docházelo tak k výpočtu záporného mezičasu. Byly dopněny statusy výsledku závodníka a přidán tag na počet vyhledaných kontrol závodníka.", "Do typu závodu v nastavení přibyla krátká trať."] },
  { version: "0.4.2", date: "2025-01-11", notes: ["V menu přibyla položka Výsledky, kde je rychlý přístup ke startovkám, výsledkům a online výsledkům.", "V sekci Členové byla přidána možnost vytvoření a editace rozhodčích a trenérů (jen pro oprávněné) a jejich přehled.", "Sekce Můj profil byla předělána. Nyní se zobrazují i přihlášky na soutěž, ne jen na etapy. Nyní se zobrazuje i umístění v etapě. Přibyl seznam praxe pro rozhodčí. Praxe se lidem přiděluje v nastavení závodu.", "Účty a přihlášky na závody nyní rozlišují národnost.", "API bylo rozšířeno o nahrávání online výsledků v csv a json formátu.", "API klíč je nyní pro celou soutěž stejný a je k dispozici v nastavení soutěže po rozbalení 'Další nastavení soutěže'.", "Byly opraveny drobné chyby a některá tlačítka byla vyměněna za ikony kvůli kompaktnosti."] },
  { version: "0.4.1", date: "2024-12-15", notes: ["Integrace výsledkového servisu.", "Integrace API klíče, pomocí kterého mohou do ROBisu nahrávat přes API data i softwary 3. strany.", "Opraven export startovky pro livelox.", "Optimalizace některých prvků pro prohlížení na malých obrazovkách."] },
  { version: "0.4.0", date: "2024-11-24", notes: ["V nastavení soutěže byla do správy přihlášených přidána pro pořadatele možnost přihlášení závodníků.", "V nastavení soutěže nyní zakladatel může přidat editory soutěže a nastavit si tak, kdo může soutěž spravovat. Organizátorům v oddíle tímto znemožní editaci soutěže.", "V nastavení uživatelů bylo upraveno pole pro vyplnění správce mých přihlášek. Nyní je možnost vybrat konkrétního uživatele robisu ze seznamu.", "Bylo upraveno zobrazení dat v xlsx souboru.", "Oprava chyb a přizpůsobení jádra pro API v připravovaném JSON ARDF formátu.", "V záložce menu Další přibyla stránka API, kde najdete předběžný přístup k API ROBisu.", "Optimalizace načítání kalendáře a stránky soutěže."] },
  { version: "0.3.2", date: "2024-10-04", notes: ["Přidán export pro Livelox na spárování závodníků s kategoriemi.", "Oprava chyb."] },
  { version: "0.3.1", date: "2024-09-28", notes: ["Seznam přihlášených závodníků je dostupný pouze na stránce soutěže, z jednotlivých etap byl odstraněn.", "K elementům, u kterých nebylo úplně jasné, co dělají, byl přidaný popis, který je dostupný po najetí myši.", "Opraveno bylo chybné načítání stránky soutěže při swipování na mobilu, optimalizace načítání záložek.", "Drobné opravy a vylepšení."] },
  { version: "0.3.0", date: "2024-09-26", notes: ["Předělán systém cen kategorií tak, aby se šlo přihlásit do hlavní kategorie bez poplatku (resp. volitelně i s poplatkem).", "V kalendáři se nyní zobrazují i informace o etapách.", "V nastavení souteže je možnost zobrazení soutěže jen pro oddíl a možnost zobrazení zrušení závodu.", "Půjčení čipu se nyní započítává k celkové částce za přihlášku a tuto cenu lze editovat ve službách v nastavení soutěže."] },
  { version: "0.2.2", date: "2024-09-09", notes: ["Start 00 pro etapy, startovní čas a cílový čas závodníka nejsou nyní převáděny podle časového pásma.", "V sekci Členové je nyní k dispozici základní filtrace. Další přibude po integraci trenérů, rozhodčích a VT.", "V oddílové sekci bylo kompletně předěláno zobrazení podle preferencí vedoucích oddílů.", "Oprava chyb a menší úpravy textu."] },
  { version: "0.2.1", date: "2024-09-07", notes: ["Opravena chyba, kdy se na závod nemohlo přihlásit více lidí jednorázovou přihláškou.", "Přidáno zobrazení počtu přihlášených na závod a počet členů v oddíle."] },
  { version: "0.2.0", date: "2024-08-23", notes: ["Integrace startovek", "Stránka se všemy Exporty, které robis aktuálně nabízí."] },
  { version: "0.1.1", date: "2024-08-12", notes: ["Email při registraci již není povinný, viz nápověda.", "Opraveny drobné chyby."] },
  { version: "0.1.0", date: "2024-08-09", notes: ["Spuštění beta verze."] },
];

export const latestVersion = versions[0].version;
