<template>
  <v-container>
      <v-col cols="12">
        <h3 class="text-h4 font-weight-bold mb-4">API ROBisu</h3>
        <p class="mb-4">
          API slouží k získání dat z databáze nebo naopak poslání dat do databáze robisu. Využívá se standardizovaných formátů dat. Pro JSON formát vycházíme z nově vytvořeného <a :href="`${BaseUrl}static/ardf_json_standard.json`" target="_blank" class="text-primary">ARDF JSON standardu</a><span class="text-red">. POZOR, je v předběžném testování, může se změnit</span>.
          Pro nahrání výsledků softwary třetích stran (vyčítací softwary) je zapotřebí poslat spolu s daty i <b>Race-Api-Key</b> v hlavičce požadavku (ne v url adrese). Tento klíč najdete na stránce nastavení etapy. Má tvar např. <code>4bc837b344e642dd86af1b04bc4313ae</code>
        </p>
        <v-expansion-panels variant="accordion">
          <!-- GET JSON event -->
          <v-expansion-panel>
            <v-expansion-panel-title>
              <span class="method btn bg-blue-accent-3">GET</span> <code class="font-weight-bold">api/?type=json&name=event&event_id=<span class="text-red">robis ID soutěže</span></code> Vrací data z celé soutěže (soutěž -> etapy -> přihlášky -> startovky -> výsledky)
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <code class="text-blue">
                https://rob-is.cz/api/?type=json&name=event&event_id=<span class="text-red">robis ID soutěže</span>
              </code>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <!-- POST JSON results -->
          <v-expansion-panel>
            <v-expansion-panel-title>
              <span class="method btn bg-green">POST</span> <code class="font-weight-bold" @click.stop>api/results/?valid=True</code> Nahraje konečné výsledky pro etapu
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
                Nahrání (a přepsání) dat týkajících se výsledků v závodu. Všechny existující výsledky v robisu se smažou, pokud neexistují v posílaném JSONu! Pokud neodesíláte pro kategorii vyhledávané kontroly, v databázi se nepřepíší na prázdnoou hodnotu, ale zůstanou v původním stavu. Pokud data v JSONu obsahují chyby, vrátí se tyto chyby v response a data se neuloží. Při odpovědi 200 (OK) se do response vypíší také přihlášení závodníci, kterým jsi výsledek v JSONu neodesílal, ale jsou na etapu příhlášení.
              </p>
              <code class="text-blue my-3">
                https://rob-is.cz/api/results/?valid=True
              </code>
              <p>
                <a :href="`${BaseUrl}static/POST_results.json`" target="_blank" class="text-primary">
                  Formát pro POST JSON final results
                </a>, 
                <a :href="`${BaseUrl}static/POST_results_example.json`" target="_blank" class="text-primary">
                  příklad dat pro odeslání
                </a>
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <!-- PUT CSV results -->
          <v-expansion-panel>
            <v-expansion-panel-title>
              <span class="method btn bg-orange-darken-2">PUT</span> <code class="font-weight-bold" @click.stop>api/results/?name=csv</code> Nahraje průběžné výsledky z&nbsp; <b>CSV souboru</b>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
                Nahrávání výsledků po částech. Data přepisují pouze výsledek závodníka, který se v datech posílá. Ostatní neukládané výsledky v databázi zůstanou. Pokud ukládáš data o závodníkovi, který v etapě není evidován, tyto data se neuloží (závodník se v robisu nezaloží). Validní data se uloží všechna. Pokud jsou některá nevalidní, response vrátí chyby pro tyto konkrétní závodníky. 

              </p>
              <ul>
                <code class="text-blue my-3">
                  https://rob-is.cz/api/results/?name=csv
                </code>
              </ul>
              <p>
                <code>
                  Index;SI number;Last name;First name;Run time;Place;Controls num.;Result status;Punches<br>
                  Punches = Code/alias,Control Type,Punch status,Split time 
                </code><br>
                Jednotlivé Punches (mezičasy) jsou oddělené # Údeje v panches jsou oddělené , Hlavní údaje jsou oddělené ; Všechny hodnoty, které v proměnných mohou nabývat naleznete <a href="https://github.com/kolskypavel/ARDF-Manager_mobile/wiki/Data-formats#result" target="_blank">na githubu</a>. Control Type nabývá hodnot: CONTROL; BEACON; FINISH; SEPARATOR. Posílaná data jsou volitelná, ale kvůli nalezení shody musíš posílat podle priority: Index nebo Příjmení a Jméno nebo Číslo čipu (pouze číslo čipu doporučujeme posílat jen v případě mezičasu z radiokontroly). Robis očekává binární soubor.

              </p>
              <p>
                <a :href="`${BaseUrl}static/PUT_results.csv`" target="_blank" class="text-primary">
                  Příklad dat pro PUT CSV online results
                </a>
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <!-- PUT JSON results -->
          <v-expansion-panel>
            <v-expansion-panel-title>
              <span class="method btn bg-orange-darken-2">PUT</span> <code class="font-weight-bold" @click.stop>api/results/?name=json</code> Nahraje průběžné výsledky z&nbsp; <b>JSON</b>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
                Nahrávání výsledků po částech. Data přepisují pouze výsledek závodníka, který se v datech posílá. Ostatní neukládané výsledky v databázi zůstanou. Pokud ukládáš data o závodníkovi, který v etapě není evidován, tyto data se neuloží (závodník se v robisu nezaloží). Validní data se uloží všechna. Pokud jsou některá nevalidní, response vrátí chyby pro tyto konkrétní závodníky. (stejné jako pro CSV)

              </p>
              <ul>
                <code class="text-blue my-3">
                  https://rob-is.cz/api/results/?name=json
                </code>
              </ul>
              <p>
               Více o formátu naleznete <a href="https://github.com/kolskypavel/ARDF-Manager_mobile/wiki/Data-formats#result" target="_blank">na githubu</a>. Posílaná data jsou volitelná, ale kvůli nalezení shody musíš posílat podle priority: Index nebo Příjmení a Jméno nebo Číslo čipu (to doporučujeme jen v případě mezičasu z radiokontroly).
              </p>
              <p>
                <a :href="`${BaseUrl}static/PUT_results.json`" target="_blank" class="text-primary">
                  Formát dat pro PUT JSON online results
                </a>,
                 <a :href="`${BaseUrl}static/PUT_results_example.json`" target="_blank" class="text-primary">
                  příklad dat pro odeslání
                </a>
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel>
                    




          <!-- PUT JSON results
          <v-expansion-panel>
            <v-expansion-panel-title class="font-weight-bold">
              <span class="method btn bg-orange-darken-2">PUT</span> <span >JSON results (konečné)</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
                Nahrání (a přepsání) dat týkajících se výsledků v závodu. Všechny existující výsledky v robisu se smažou, pokud neexistují v
                posílaném JSONu! Vyžaduje <b>Event_Api_Key</b>.
              </p>
              <ul class="mb-3">
                <code class="text-blue">
                  https://rob-is.cz/api/results/?valid=True&race_id=<span class="text-red">robis ID etapy</span>
                </code>
              </ul>
              <p>
                put text
              </p>
              <p>
                <a :href="`${BaseUrl}static/POST_results.json`" target="_blank" class="text-primary">
                  Formát pro POST JSON final results
                </a>
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel>-->
          <!-- DELETE JSON results -->
          <!-- <v-expansion-panel>
            <v-expansion-panel-title class="font-weight-bold">
              <span class="method btn bg-deep-orange-accent-4">DELETE</span> <span >JSON results (konečné)</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>
               text
              </p>
              <p>
                <a :href="`${BaseUrl}static/POST_results.json`" target="_blank" class="text-primary">
                  Formát pro POST JSON final results
                </a>
              </p>
            </v-expansion-panel-text>
          </v-expansion-panel> --> 

        </v-expansion-panels>
      </v-col>


  </v-container>
</template>

<script setup>
import { ref } from "vue";
const BaseUrl = ref(process.env.VUE_APP_API_STATIC_URL);
</script>

<style scoped>
.text-blue {
  color: #1e88e5;
}
.text-red {
  color: #e53935;
}
.text-primary {
  text-decoration: underline;
  color: #1976d2;
}
.method {
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 10px;
}


code {
  background-color: #f5f5f5;
  padding: 4px 8px;
  margin-right: 10px;
  border-radius: 4px;
  display: inline-block;
  word-break: break-all;
}
p {
  text-align: justify;
  line-height: 27px;
}
</style>
