<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3>Exporty</h3>
        
        <h4 class="mt-3">XLSX (Excel):</h4>
        <p>Určeno pro pořadatele a oddíly k evidenci přihlášených.</p>
        <ul>
                <li>Seznam přihlášených - <code>https://rob-is.cz/export/?type=xlsx&event_id=<span class="blue">robis ID soutežě</span></code></li>
            </ul>
        <h4>CSV:</h4>
        <ul>
            <li><a :href="`${BaseUrl}/export/?type=csv&name=robis_accounts`">Seznam závodníků s čísly čipů a indexy</a></li>
            <li>Seznam přihlášených Matula - <code>https://rob-is.cz/export/?type=csv&name=entry_matula&event_id=<span class="blue">robis ID soutežě</span></code></li>
            <li>Seznam přihlášených OCM Manager - <code>https://rob-is.cz/export/?type=csv&name=entry_ocm&event_id=<span class="blue">robis ID soutežě</span></code></li>
            <li>Startovní listina - <code>https://rob-is.cz/export/?type=csv&name=startlist&race_id=<span class="blue">robis ID závodu</span></code></li>

        </ul>

        <h4>XML IOF 3.0:</h4>
        <ul>
            <li>Výsledky - <code>https://rob-is.cz/export/?type=xml&name=results&race_id=<span class="blue">robis ID soutežě</span></code></li>
        </ul>


        <h4>PDF:</h4>
        <ul>
            <li>Startovní listina po časech - <code>https://rob-is.cz/export/?type=pdf&name=startlist_starter&race_id=<span class="blue">robis ID závodu</span></code></li>
        </ul>

        <!-- <h4>XML:</h4>
        <p>Standard IOF 3.0</p>
            <ul>
                <li>Seznam přihlášených  - *URL SOUTĚŽE*/<code>ucastnici_xml/</code></li>
                <li>Startovka - *URL SOUTĚŽE*/<code>startlist_xml/</code></li>
            </ul> -->
          <h4>SI-Droid:</h4>
            <ul>
              <li><a :href="`${BaseUrl}/export/?type=csv&name=si_droid_all`">Seznam všech registrovaných</a></li>
              <li>Seznam přihlášených pro závod <code>https://rob-is.cz/export/?type=csv&name=si_droid_race&race_id=<span class="blue">robis ID závodu</span></code></li>
            </ul> 

        <h4>Livelox:</h4>
        <ul>
            <li>Seznam přihlášených v etapě - <code>https://rob-is.cz/export/?type=xml&name=livelox_startlist&race_id=<span class="blue">robis ID závodu</span></code></li>
            <li>Výsledky v etapě - <code>https://rob-is.cz/export/?type=xml&name=results&race_id=<span class="blue">robis ID soutežě</span></code></li>
        </ul>

      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue';

const BaseUrl = ref(process.env.VUE_APP_API_BASE_URL.replace(/\/+$/, '').replace(/\/api\/?$/, '')); // Odstraní "api/" a nadbytečná lomítka

</script>

<style scoped>
ul {
  margin-left:40px;
}

.blue {
  color:blue;
}

h4 {
  margin-top: 10px;
}

code {
  background-color: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
}


</style>
