<template>
    <v-row class="mx-3">
      <v-col>
        <v-card>
          <v-card-title class="d-flex align-center">
            Rozhodčí
            <v-spacer/>
            <v-icon color="green" size="40" title="Vytvořit rozhodčího" v-if="isManager"
                    @click="createOrEditRole('referee',true,null)">
              mdi-plus
            </v-icon>
          </v-card-title>
          <v-data-table
              density="compact"
              hide-default-footer="true"
              :headers="roleHeaders"
              :items="rolesReferee"
              class="elevation-1"
              :items-per-page="-1"
              :sort-by="roleSortBy"
              :loading="loading"
              loading-text="Načítám data..."
              no-data-text="Nenalezeny žádné třídy"
          >
            <template v-slot:[`item.lvl`]="{ item }">
              {{ item.lvl }}
            </template>
            <template v-slot:[`item.valid_from`]="{ item }">
              {{ $formatDate(item.valid_from) }}
            </template>
            <template v-slot:[`item.valid_to`]="{ item }">
              {{ $formatDate(item.valid_to) }}
            </template>
            <template v-slot:[`item.created_by`]="{ item }">
              {{ item.created_by }}
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span style="white-space: nowrap;">{{ item.description }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }" v-if="isManager">
              <v-list-item-action>
                <v-icon @click="createOrEditRole('referee',false,item)" color="blue" size="30" class="mr-2"
                        title="Editovat roli">mdi-pencil
                </v-icon>
                <v-icon @click="openDeleteDialog(item)" color="red" size="30" class="mr-2"
                        title="Smazat roli">mdi-delete
                </v-icon>
              </v-list-item-action>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title class="d-flex align-center">
            Trenér
            <v-spacer/>
            <v-icon color="green" size="40" title="Vytvořit trenéra" v-if="isManager"
                    @click="createOrEditRole('coach',true,null)">mdi-plus
            </v-icon>
          </v-card-title>

          <v-data-table
              density="compact"
              hide-default-footer="true"
              :headers="roleHeaders"
              :items="rolesCoach"
              class="elevation-1"
              :items-per-page="-1"
              :sort-by="roleSortBy"
              :loading="loading"
              loading-text="Načítám data..."
              no-data-text="Nenalezeny žádné třídy"
          >
            <template v-slot:[`item.lvl`]="{ item }">
              {{ item.lvl }}
            </template>
            <template v-slot:[`item.valid_from`]="{ item }">
              {{ $formatDate(item.valid_from) }}
            </template>
            <template v-slot:[`item.valid_to`]="{ item }">
              {{ $formatDate(item.valid_to) }}
            </template>
            <template v-slot:[`item.created_by`]="{ item }">
              {{ item.created_by }}
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span style="white-space: nowrap;">{{ item.description }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }" v-if="isManager">
              <v-list-item-action>
                <v-icon @click="createOrEditRole('coach',false,item)" color="blue" size="30" class="mr-2"
                        title="Editovat roli">mdi-pencil
                </v-icon>
                <v-icon @click="openDeleteDialog(item)" color="red" size="30" class="mr-2" title="Smazat roli">
                  mdi-delete
                </v-icon>
              </v-list-item-action>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>


  <TheRoleEditDialog
      v-model="roleDialogOpen"
      :dialog="roleDialogOpen"
      :role-type="selectedRoleType"
      :create="create"
      :account-id="props.accountId"
      :role="selectedRole"
      :excluded-role-levels="excludedRoleLevels"
      @role-saved="updateRoles(true)"
      @close-dialog="roleDialogOpen=false"
  />

  <!-- Dialog pro potvrzení smazání role -->
  <v-dialog v-model="dialogDeleteRole" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-h5">Potvrzení smazání třídy</v-card-title>
      <v-card-text>
        <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
        Opravdu chceš smazat třídu?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialogDeleteRole = false">Zrušit</v-btn>
        <v-btn color="red darken-1" text @click="deleteRole">Smazat třídu</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>

<script setup>

import {onMounted, ref, computed} from "vue";
import apiClient from '/api.js';
import {useAuthStore} from '/auth.js';
import TheRoleEditDialog from "@/components/TheRoleEditDialog.vue";
import {useSnackbarStore} from '@/stores/snackbarStore'

const authStore = useAuthStore();
const snackbar = useSnackbarStore()


// eslint-disable-next-line no-undef
const props = defineProps({
  accountId: Number
})

const loading = ref(true);
const errorMessage = ref("")

const rolesReferee = ref([]);
const rolesCoach = ref([]);

// Proměnné pro vytváření / editaci rolí
const create = ref(false);
const roleDialogOpen = ref(false);
const selectedRole = ref(null);
const selectedRoleType = ref(null);

const isManager = authStore.roles.includes('manager');  // Načtení práv
const roleSortBy = [{key: 'valid_to', order: 'desc'}];

const roleHeaders = [
  {title: 'Třída', key: 'lvl', align: 'left', sortable: false},
  {title: 'Platnost od', key: 'valid_from', align: 'left', sortable: false},
  {title: 'Platnost do', key: 'valid_to', align: 'left', sortable: true},
  {title: 'Udělil', key: 'created_by', align: 'left', sortable: false},
  {title: 'Poznámka', key: 'description', align: 'left', sortable: false},
  ...(isManager ? [{title: 'Akce', value: 'actions', align: 'center', width: '30px', sortable: false}] : []), // Přidat sloupec jen pro 'manager'
];

// Načítání rolí uživatele
const loadRoles = async () => {
  try {
    const response = await apiClient.get(`account/role/?account_id=${props.accountId}`);
    rolesReferee.value = response.data["referees"];
    rolesCoach.value = response.data["coaches"];
  } catch (error) {
    console.error(`There was an error fetching the roles for accountId (${props.accountId}):`, error);
  } finally {
    loading.value = false
  }
}

const excludedRoleLevels = computed(() => {
  const now = new Date();
  if (selectedRoleType.value === 'referee') {
    const activeRole = rolesReferee.value.find(r => new Date(r.valid_to) > now);
    return activeRole ? [activeRole.lvl] : [];
  } else if (selectedRoleType.value === 'coach') {
    const activeRole = rolesCoach.value.find(r => new Date(r.valid_to) > now);
    return activeRole ? [activeRole.lvl] : [];
  }
  return [];
});


const createOrEditRole = (roleType, isCreated, role) => {
  roleDialogOpen.value = true;
  selectedRoleType.value = roleType;
  create.value = isCreated
  selectedRole.value = role;
};

const dialogDeleteRole = ref(false);
const openDeleteDialog = (role) => {
  selectedRole.value = role;
  errorMessage.value = "";
  dialogDeleteRole.value = true;
};

const updateRoles = (showMessage) => {
  if (showMessage) {
    errorMessage.value = "";
    snackbar.showSnackbar('Třída úspěšně uložena.');
  }

  roleDialogOpen.value = false;
  loadRoles();
}

const deleteRole = async () => {
  try {

    // Odeslání požadavku DELETE na API
    await apiClient.delete(`account/role/?role_id=${selectedRole.value.role_id}`, {});

    snackbar.showSnackbar("Třída úspěšně smazána.");
    errorMessage.value = "";
    dialogDeleteRole.value = false; // Zavření potvrzovacího dialogu
    updateRoles(false)
  } catch (error) {
    const detail = error.response.data.detail || 'Neznámá chyba.';
    console.error('Chyba při mazání třídy:', error.message);
    errorMessage.value = `Nepodařilo se smazat třídu. ${detail}`;
  }
};

onMounted(() => {
  loadRoles(props.accountId)
});

</script>