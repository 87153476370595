<template>
  <v-container class="form-signin">
    <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
    <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>
    <h2 class="mb-4">Registrace jednotlivce</h2>
    <v-form @submit.prevent="registerUser">
      <v-row>
        <v-col cols="12" md="6"><v-text-field v-model="formData.first_name" label="Křestní jméno" required :rules="[rules.firstName]" /></v-col>
        <v-col cols="12" md="6"><v-text-field v-model="formData.last_name" label="Příjmení" required :rules="[rules.last_name]" /></v-col>

        <!-- <v-text-field v-model="formData.password1" label="Heslo" type="password" :rules="[rules.password]" required></v-text-field>
        <v-text-field v-model="formData.password2" label="Zopakovat heslo" type="password" :rules="[v => v === formData.password1 || 'Hesla se neshodují']" required></v-text-field> -->

        <v-col cols="12" md="6"><v-text-field v-model="formData.email" label="Email" :rules="[rules.isValidEmail]" /></v-col>
        <v-col cols="12" md="6"><v-text-field v-model="formData.si_number" type="number" label="Číslo čipu" :rules="[rules.siNumber]" /></v-col>

        <!-- <v-col cols="12" md="2"><CountrySelect v-model="formData.country" label="Země" /></v-col> -->

        <v-col cols="12" md="4"><v-text-field event_type_display v-model="formData.birth_year" label="Rok narození" type="number" :rules="[rules.year]" /></v-col>
        <v-col cols="12" md="4"><v-select v-model="formData.gender" :items="sexOptions" label="Pohlaví" :rules="[rules.gender]" /></v-col>
        <v-col cols="12" md="4" v-if="!isInternational">
          <v-row no-gutters>
            <v-text-field v-model="generatedIndexPart" label="" disabled style="max-width:88px;min-width:88px" />
            <v-text-field v-model="userIndexPart" label="index" required :rules="[rules.userIndexPart]" />
          </v-row>
        </v-col>
      </v-row>

      <v-btn :loading="isLoading" class="my-3" type="submit" color="primary" block :disabled="!isFormValid">Vytvořit účet</v-btn>
    </v-form>
    <h2 class="my-4">Registrace hromadně</h2>
    <span style="color:red">Kvůli velké náročnosti vytváření členů v databázi jde naráz nahrát pouze zhruba 20 záznamů. Prosím, ukládejte členy postupně po tomto počtu.</span>
  
    <v-form @submit.prevent="registerUsers" class="form">
      <v-row class="my-2">
        <v-col cols="auto" class="line-numbers">
          <div v-for="n in linesCount" :key="n" class="line-number">{{ n }}</div>
        </v-col>
        <v-col>
          <v-textarea
            v-if="!isInternational"
            v-model="bulkUserData"
            @input="updateLinesCount"
            label="Příklad: Novotný;Petr;XXX8501;petr.novotny@email.cz;1985;M;25687"
            hint="Zadejte každého uživatele na nový řádek ve formátu: Příjmení;Jméno;index;email;rok narození;pohlaví (M nebo F);číslo SI"
            persistent-hint
            auto-grow
            class="textarea-with-lines"
          />
          <v-textarea
            v-else
            v-model="bulkUserData"
            @input="updateLinesCount"
            label="Example: Novotny;Petr;petr.novotny@email.com;1985;M;25687"
            hint="Every single user should be in separate line in format: Last name;First name;email;birth year;gender (M or F);SI number"
            persistent-hint
            auto-grow
            class="textarea-with-lines"
          />

        </v-col>
      </v-row>
      <v-btn :loading="isLoadingBulk" type="submit" color="primary" block :disabled="!bulkUserData">Provést hromadnou registraci</v-btn>
      <br>
      <v-alert type="error" v-if="errorMessageUsers" :icon="false">
        <div v-for="(error, index) in errorMessageUsers" :key="index">
          {{ error }}
        </div>
      </v-alert>
      <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>
    </v-form>
  </v-container>
</template>


<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import apiClient from '/api.js';
import { useAuthStore } from '/auth.js';
// import CountrySelect from '@/components/shared/CountrySelect.vue';
// import { countryCodeMap } from "@/utils/countryCodes.js";
// import { useRouter } from 'vue-router';

const authStore = useAuthStore(); // Použití Auth Store
const bulkUserData = ref('');
const linesCount = ref(1);

const generatedIndexPart = ref('');
const userIndexPart = ref('');

const isLoading = ref(false);
const isLoadingBulk = ref(false); 

// Získání prvních tří písmen z indexu a předvyplnění pole index
onMounted(() => {
  formData.value.index = authStore.index.substring(0, 3);
  updateLinesCount();
});

// Zjištění, jestli je uživatel z česka nebo international a podle toho se mu zobrazí typ registrace uživatelů
const isInternational = computed(() => {
  return /^[IJKLMNOPQSTUVWXY]/.test(authStore.index);
});

const formData = ref({
  email: '',
  // password1: '',
  // password2: '',
  first_name: '',
  last_name: '',
  index: '',
  // country: '',
  gender: '',
  birth_year: '',
  si_number: '',
  country: isInternational.value ? "" : "CZE",
});

const rules = computed(() => ({
  
  isValidEmail: value => (!value || /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) || 'Neplatný email',
  year: value => (value && value >= 1900 && value <= new Date().getFullYear()) || 'Neplatný rok.',
  siNumber: value => value === '' || /^\d{4,8}$/.test(value) || 'Číslo SI musí mít 4 až 8 cifer.',
  firstName: value => (value.trim() !== '') || 'Jméno je povinné pole.',
  last_name: value => (value.trim() !== '') || 'Příjmení je povinné pole.',
  gender: value => (['M', 'F'].includes(value)) || 'Pohlaví je povinné pole.',
  // Použij pravidlo `userIndexPart` jen pokud uživatel **není** mezinárodní
  userIndexPart: value => {
    if (!isInternational.value) {
      return (/^\d\d$/.test(value)) || 'Musí být 2 čísla.';  // Pravidlo pro poslední dvě číslice indexu
    }
    return true; // Při mezinárodní registraci je validní vždy
  }
  }));



// const router = useRouter();
const errorMessage = ref(''); 
const errorMessageUsers = ref('');  // Error message reactive variable

const successMessage = ref(''); 

const sexOptions = [{title: 'Muž', value: 'M'}, {title: 'Žena', value: 'F'}]
// const countryOptions = [{title: 'Česká republika', value: 'CZE'}] 


// Zabránění kliknutí na tlačítka registrovat, pokud chybí některé z polí 
const isFormValid = computed(() => {
  return rules.value.isValidEmail(formData.value.email) === true &&
         rules.value.firstName(formData.value.first_name) === true &&
         rules.value.last_name(formData.value.last_name) === true &&
         rules.value.year(formData.value.birth_year) === true &&
         rules.value.gender(formData.value.gender) === true &&
         rules.value.siNumber(formData.value.si_number) === true &&
         (!isInternational.value || rules.value.userIndexPart(userIndexPart.value) === true);
});

const registerUser = async () => {
  if (!isFormValid.value) {
    errorMessage.value = 'Některé z polí nejsou správně vyplněné.';
    return;
  }

  isLoading.value = true;

  try {
    const response = await apiClient.post(`account/create/?international=${isInternational.value}`, formData.value);
    if (response.status === 201) {
      successMessage.value = 'Uživatel byl úspěšně zaregistrován!';
      formData.value = {
        email: '',
        // password1: '',
        // password2: '',
        first_name: '',
        last_name: '',
        index: '',
        // country: '',
        gender: '',
        birth_year: '',
        si_number: ''
      };
      errorMessage.value = '';
    }
  } catch (error) {
    console.error('Chyba při registraci uživatele:', error);
    if (error.response && error.response.data) {
      // Zde předpokládáme, že chybová zpráva je přímo v 'data' jako string nebo jako objekt s klíčem 'error'
      const errorMsg = error.response.data.error || "Účet se nepodařilo vytvořit. Zkontrolujte zadané informace.";
      errorMessage.value = Array.isArray(errorMsg) ? errorMsg.join(', ') : errorMsg;
    } else {
      errorMessage.value = "Účet se nepodařilo vytvořit. Zkuste nahrát menší počet záznamů (20 a méně).";
    }
    successMessage.value = '';
  } finally {
    isLoading.value = false; // Nastavte stav načítání zpět na false
  }
};

// Hromadná registrace
const registerUsers = async () => {
  isLoadingBulk.value = true;
  const users = bulkUserData.value.split('\n').filter(line => line.length >= 5).map(line => {
    if (isInternational.value) {
        // Mezinárodní formát (bez indexu)
        const [last_name, first_name, email, birth_year, gender, si_number] = line.split(';');
        return { last_name, first_name, email, birth_year, gender, si_number: si_number || null };
      } else {
        // Český formát (s indexem)
        const [last_name, first_name, index, email, birth_year, gender, si_number] = line.split(';');
        return { last_name, first_name, index, email, birth_year, gender, si_number: si_number || null };
      }
    });
  try {
    const response = await apiClient.post(`account/create_bulk/?international=${isInternational.value}`, users);
    console.log('Všichni uživatelé byli úspěšně zaregistrováni', response.data);
    successMessage.value = 'Všichni uživatelé byli úspěšně vytvořeni.';
    errorMessageUsers.value = '';


  } catch (error) {
    console.error('Chyba při registraci uživatele:', error.response.data);
    // Zpracování chybových zpráv
    if (error.response && error.response.data) {
      // Zpracování chyb, kontrola jestli pole 'errors' existuje a má obsah
      if (Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
        errorMessageUsers.value = error.response.data.errors; // Uložte chyby jako pole
      } else {
        errorMessageUsers.value = ["Účty se nepodařilo vytvořit. Nejspíše bude chyba ve vstupních datech."];
      }
      successMessage.value = '';
    } else {
      // V případě, že chybová odpověď neobsahuje očekávanou strukturu
      errorMessageUsers.value = "Účty se nepodařilo vytvořit.";
      successMessage.value = '';
    }
  } finally {
    isLoadingBulk.value = false; // Nastavte stav načítání zpět na false
  }
};

// Watcher pro birth_year, sex a userIndexPart
watch([() => formData.value.birth_year, () => formData.value.gender], ([newYear, newSex]) => {
  if (newYear && newYear.length === 4) {
    const lastTwoDigits = newYear.slice(-2); // Poslední dvě číslice roku
    generatedIndexPart.value = authStore.index.substring(0, 3) + lastTwoDigits;
  }

  let suffix = ''; // Přípona založená na pohlaví
  if (newSex === 'M') {
    suffix = '0';
  } else if (newSex === 'F') {
    suffix = '5';
  }

  userIndexPart.value = suffix;
}, { immediate: true });

watch([() => userIndexPart.value], ([newUserIndexPart]) => {
  formData.value.index = generatedIndexPart.value + newUserIndexPart;
});


// Funkce pro aktualizaci počtu řádků vedle textarey pro hromadnou registraci
const updateLinesCount = () => {
  linesCount.value = bulkUserData.value.split('\n').length;
};

</script>

<style scoped>
.form-signin {
  max-width: 760px!important;
  margin: auto;
}



.line-numbers {
  text-align: right;
  padding-right: 10px;
  margin-bottom: 34px;
  margin-top:25px;
  color: grey;
  background-color: #f9f9f9; /* Light grey background to distinguish the line numbers */
  border-right: 1px solid #ddd; /* Separator line */
}

.line-number {
  line-height: 1.6; /* Adjust based on your font-size and textarea line-height */
}

.textarea-with-lines {
  min-height: 100px; /* Adjust based on your needs */
  line-height: 1.6; /* Match the line number line height */
}

</style>
