<template>
  <v-container>
    <v-col cols="12" md="2" >
      <h4>Počet škrtaných závodů: {{ ranking.drop_count }}</h4>
    </v-col>

    <RankingTable 
    :loading="loading" 
    :ranking="ranking" 
    @refreshRanking="fetchRanking" 
    />

    <!-- Zobrazení zprávy, pokud nejsou závody -->
    <v-alert v-if="!ranking.races.length && !ranking.categories.length && !loading" type="info" class="mt-4">
      Zatím nejsou vypsané žádné závody do národního žebříčku.
    </v-alert>

  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import apiClient from '/api.js';
import RankingTable from '@/components/shared/RankingTable.vue';

const loading = ref(true);
const ranking = ref({ races: [], categories: [], drop_count: 0 });
const currentYear = new Date().getFullYear();

onMounted(async () => {
  await fetchRanking();
});

const fetchRanking = async () => {
  loading.value = true;

  try {
    const response = await apiClient.get(`/ranking/national/${currentYear}/`);
    ranking.value = response.data || { races: [], categories: [] };
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};
</script>
