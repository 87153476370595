<template>
  <v-container>
    <!-- Výběr roku pomocí SelectYear komponenty -->
    <v-row class="mb-4 align-center justify-space-between">
      <div sm="2"></div>
      <v-col cols="12" sm="3" class="order-2 order-sm-1 text-sm-left text-center">
        <h3>Bilance: <span style="color:red">{{ totalFee }} Kč</span></h3>
      </v-col>
      <v-col cols="12" sm="2" class="order-1 order-sm-2 text-sm-right">
        <SelectYear v-model="selectedYear" />
      </v-col>
    </v-row>

    <!-- Datová tabulka s údaji z eventu -->
    <v-data-table
      density="compact"
      :headers="headers"
      :items="payments"
      class="elevation-1"
      :items-per-page="50"
      loading-text="Načítám data..."
      :loading="loading" 
      hide-default-footer="true"
      :sort-by="[{ key: 'event_date_start', order: 'asc' }]"
      no-data-text="Nenalezeny žádné záznamy o platbách"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ $formatShortDate(item.event_date_start) }}
            <template v-if="item.event_date_start !== item.event_date_end">
              – {{ $formatShortDate(item.event_date_end) }}
            </template>
          </td>
          <td>
            <router-link :to="'/soutez/' + item.event_id">{{ item.event_name }}</router-link>
          </td>
          <td>{{ item.fee_categories }} Kč</td>
          <td>{{ item.fee_accommodation_food }} Kč</td>
          <td>{{ item.fee_other_services }} Kč</td>
          <td><strong>{{ item.fee_summary }} Kč</strong></td>
        </tr>
      </template>

    </v-data-table>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import apiClient from '/api.js';
import SelectYear from '@/components/shared/SelectYear.vue'; // Import tvé komponenty

const payments = ref([]);
const selectedYear = ref(new Date().getFullYear()); // Defaultně aktuální rok

// Sloupce tabulky
const headers = [
  { title: 'Datum soutěže', value: 'event_date_start' },
  { title: 'Soutěž', value: 'event_name' },
  { title: 'Startovné', value: 'fee_categories', sortable: true },
  { title: 'Strava a ubytování', value: 'fee_accommodation_food', sortable: true },
  { title: 'Další služby', value: 'fee_other_services', sortable: true },
  { title: 'Celková částka', value: 'fee_summary', sortable: true },
];

const loading = ref(true);

// Načtení dat pro vybraný rok
async function fetchPayments() {
  loading.value = true;
  try {
    const response = await apiClient.get('payment/', {
      params: { year: selectedYear.value }
    });
    payments.value = response.data;
  } catch (error) {
    console.error('Error fetching payments:', error);
  } finally {
    loading.value = false;  // Nastavíme loading na false po načtení dat
  }

}

// Sleduj změnu roku a načítej data
watch(selectedYear, fetchPayments);

onMounted(fetchPayments);

// Celková částka
const totalFee = computed(() =>
  payments.value.reduce((sum, item) => sum + (item.fee_summary || 0), 0)
);
</script>
